import styled, { css } from "styled-components";

const RegularFont = "Rubik-Regular";
const MediumFont = "Rubik-Medium";
const LightFont = "Rubik-Light";
const Container = styled.div`
  display: inline-block;
  width: ${({ behaviorChallenge }) => (behaviorChallenge ? "100%" : "35%")};
  background-color: white;
  float: left;
  box-shadow: 1px 1.732px 46px rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  margin-right: 20px;
  margin-top: ${({ behaviorChallenge }) => !behaviorChallenge && "20px"};
  height: 235px;

  @media (max-width: 1200px) {
    width: 100%;
    height: auto;
  }
`;

const NavigationBox = styled.div`
  width: 100%;
  float: left;
  min-height: ${({ isDailyFitnessActivity }) =>
    isDailyFitnessActivity ? "100%" : "210px"};
  text-align: center;
  position: relative;
  /* background: ${({ isDailyFitnessActivity, bgColor }) =>
    isDailyFitnessActivity
      ? "linear-gradient(180deg, #1E6481 0%, #022C43 100%)"
      : bgColor
        ? "linear-gradient(180deg, #E2FCD3 0%, rgba(255, 255, 255, 0)100%), #FFFFFF"
        : "linear-gradient(180deg, #FCE7D3 0%, rgba(255, 255, 255, 0)100%), #FFFFFF"}; */
  background: linear-gradient(133.03deg, #AF87C1 25.62%, #6781C3 73.49%);
  border-radius: 6px;
  box-shadow: ${({ isDailyFitnessActivity }) =>
    isDailyFitnessActivity ? "0px 8px 25px 0px #0D427040" : "0px 4px 4px rgba(0, 0, 0, 0.25)"};
  img {
    width: 100%;
    height: 100%;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  > ul {
    list-style-type: none;
    position: absolute;
    left: 20px;
    bottom: calc(50% - 100px);
    margin-bottom: 0px;
    padding-left: 0px;

    > li {
      float: left;
      padding-left: 5px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
    }
  }
`;

const DailyTip = styled.div`
  width: 100%;
  text-align: left;
  position: relative;
  font-family: Rubik-Regular;
  font-size: 14px;
  color: #ffffff;
  padding: 0px 30px 35px 30px;
  display: flex;
  flex-direction: column;
  img {
    width: 15px;
    height: 20px;
    margin-left: auto;
    margin-bottom: 5px;
  }
  span {
    width: 100%;
    padding-top: 4px;
    font-family: ${RegularFont};
    color: #484856;
    font-size: 14px;
    line-height: 24px;
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    @media (max-width: 1200px) {
      padding-top: 0px;
    }
    @media (max-width: 390px) {
      padding-top: 5px;
    }
  }
`;

const Author = styled.div`
  height: 15px;
  font-family: ${RegularFont};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #484856;
  padding: 20px 0px 0px;
`;

const DailySummaryDiv = styled.div`
  display: inline-block;
  width: calc(65% - 20px);
  height: 235px;
  position: ${(props) => props.position};
  background: ${({ isYourActivityOverview }) =>
    isYourActivityOverview ? "#022C43" : "white"};
  float: left;
  box-shadow: 1px 1.732px 46px rgba(0, 0, 0, 0.18);
  margin-top: 20px;
  border-radius: 5px;

  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const Top = styled.div`
  height: 75%;
  position: relative;

  >div: first-child {
    height: 100%;
  }
  img {
    cursor: auto;
    width: 100%;
    height: 100%;
  }
  .overlay {
    background: rgb(0, 0, 0);
    opacity: 0.4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .calenderIcon {
    > svg {
      width: 15%;
      height: 15%;
      position: absolute;
      bottom: 7%;
      left: 1%;

      @media (max-width: 767px) {
        left: 5%;
      }

      @media (max-width: 530px) {
        width: 22%;
        height: 22%;
        left: 0%;
      }
    }

    .month {
      position: absolute;
      bottom: 11%;
      left: 13%;
      color: #ffffff;
      font-family: ${RegularFont};
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      text-transform: uppercase;

      @media (max-width: 767px) {
        left: 16%;
      }
    }

    .currentDay {
      position: absolute;
      bottom: 4%;
      left: 83%;
      color: #ffffff;
      font-family: ${RegularFont};
      font-style: normal;
      font-weight: bold;
      font-size: 35px;
      text-transform: uppercase;
    }

    .day {
      position: absolute;
      bottom: 26%;
      left: 83%;
      color: #ffffff;
      font-family: ${RegularFont};
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      text-transform: uppercase;

      @media (max-width: 530px) {
        bottom: 35%;
      }

      @media (max-width: 330px) {
        bottom: 38%;
      }
    }
  }
  .calendarBox {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .calendarDate {
      img {
        width: 25px;
        height: 25px;
      }
      span {
        text-transform: uppercase;
      }
    }
    span {
      font-family: Rubik-Regular;
      font-size: 11px;
      color: #fff;
      margin-left: 7px;
      display: inline-block;
      font-weight: 700;
    }
    .dateContainer {
      display: flex;
      align-items: flex-end;
      >div: first-child {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        >span: first-child {
          font-weight: 100;
          font-family: Rubik-Light;
          padding-bottom: 2px;
        }
      }
      .date {
        font-size: 35px;
        font-weight: 900;
        line-height: 25px;
        font-family: Rubik-Medium;
      }
      > span {
        font-weight: 100;
        margin-left: 5px;
      }
    }
  }
`;

const BottomContainer = styled.div`
  min-height: 25%;
  width: 100%;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const CaloriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 50%;
  border-right: 1px solid #d2d2d2;
  display: inline-block;
  padding: 10px 0 5px 0;
  text-align: center;
  @media (max-width: 480px){
    width: 100%;
    border-right: none;
  }
  > div:first-child {
      display: inline-block;
      line-height: 1;
      text-align: left;

      > div: first-child {
        font-family: Helvetica;
        font-size: 17.52px;
        line-height: 15px;
        font-weight: bold;
        color: #185873;

        @media (max-width: 767px) {
          padding-left: 10px;
        }
      }
      > span {
        display: inline-block;
        font-family: ${RegularFont};
        font-style: normal;
        font-weight: normal;
        font-size: 9.44px;
        @media (max-width: 767px) {
          margin-left: 12px;
        }
      }
    }
  }

  > div {
    height: auto;
    width: auto;
    display: inline-block;
    vertical-align: middle;

    > img {
      cursor: auto;
      width: 50px;
      height: auto;
      padding: 5px;
      margin: 0 8px;
      @media (max-width: 767px) {
        margin-left: 20px;
      }
    }
  }
  .caloriRemaining {
    height: auto;
    width: 30%;
    padding-left: 0;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    > div {
      line-height: 1;
      font-family: Helvetica;
      font-size: 17.52px;
      color: #67676A;
      font-weight: bold;
      > span {
        display: inline-block;
        font-size: 10px;
        font-weight: normal;
        margin-left: 2px;
      }
    }
  
    > span {
      display: block;
      font-size: 9px;
    }
  }
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 50%;
  display: inline-block;
  padding: 10px 0 5px 0;
  text-align: center;
  @media (max-width: 480px){
    width: 100%;
  }
  > div:first-child {
      display: inline-block;
      line-height: 1;
      text-align: left;

      > div: first-child {
        font-family: Helvetica;
        font-size: 14px;
        line-height: 15px;
        color: #185873;
        font-weight: bold;
        @media (max-width: 767px) {
          padding-left: 10px;
        }
        > span {
          margin-left: 5px;
          font-size: 11px;
          color: #67676A;
          font-family: Helvetica;;
        }
      }
      > span {
        display: inline-block;
        font-family: ${RegularFont};
        font-style: normal;
        font-weight: normal;
        font-size: 9.44px;
        @media (max-width: 767px) {
          margin-left: 12px;
        }
      }
    }
  }

  > div {
    height: auto;
    width: auto;
    display: inline-block;
    vertical-align: middle;

    > img {
      cursor: auto;
      width: 50px;
      height: auto;
      padding: 5px;
      margin: 0 8px;  
    }
  }
  .caloriRemaining {
    height: auto;
    width: 30%;
    padding-left: 0;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    > div:first-child {
      line-height: 1;
      font-family: Helvetica;
      font-size: 14px;
      color: #67676A;
      font-weight: bold;
      > span {
        display: inline-block;
        font-size: 10px;
        margin-left: 5px;
      }
    }
  
    > div:nth-child(2) {
      word-break: break-word;
      display: inline-block;
      font-size: 9px;
      white-space: pre-line;
    }
  }
`;

const TodaysChallengeDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 86px;
  float: left;
  background: ${({ bgColor }) =>
    bgColor
      ? "linear-gradient(90deg, #1E76AB 0%, #5DD7A4 100%)"
      : "linear-gradient(90deg, #1E76AB 0%, #7B5DD7 100%)"};
  border-radius: 6px;
  margin-right: 20px;
  margin-top: 10px;
  @media (max-width: 550px) {
    flex-direction: column;
  }
  .showMessage {
    height: 60px;
    font-family: Rubik;
    font-size: 14px;
    color: #ffffff;
    font-style: normal;
    word-break: break-word;
    font-weight: bold;
    letter-spacing: 1px;
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }

  .continue {
    float: right;
    font-family: ${RegularFont};
    font-style: normal;
    opacity: 0.7;
    font-size: 13px;
    color: #ffffff;
    margin-right: 25px;
    margin-top: 5px;
    cursor: pointer;
    width: 85px;
    text-align: right;
    > span {
      font-family: ${RegularFont};
      font-size: 14px;
      color: #ffffff;
      vertical-align: middle;
      margin-right: 10px;
    }
    > i {
      @media (max-width: 767px) {
        right: 0px;
        margin-left: 0px;
        margin-right: 10px;
        position: relative;
      }

      @media (max-width: 320px) {
        right: 10px;
      }
    }
    @media (max-width: 550px) {
      text-align: center;
      margin: 10px auto;
    }
  }
`;

const RightArrow = styled.i`
  right: 25px;
  line-height: 23px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 3px !important;

  @media (max-width: 767px) {
    margin-left: 20px;
    right: 30px;
    position: absolute;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
  height: 60px;
  width: 85px;
  background: #4798d9;
  border-radius: 2px;
  margin: 13px 0 13px 30px;
  > div:first-child {
    text-align: center;
    > span {
      font-family: ${MediumFont};
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase;
      margin: 0 5px;
    }
  }
  .year {
    font-family: ${MediumFont};
    font-style: normal;
    font-size: 15px;
    line-height: 17px;
    color: #ffffff;
    text-align: center;
  }
  @media (max-width: 550px) {
    margin: 10px auto;
  }
`;

const ChallengeContainer = styled.div`
  float: left;
  font-style: normal;
  color: #ffffff;
  height: 100%;
  width: calc(100% - 223px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > div:first-child {
    font-family: ${LightFont};
    vertical-align: middle;
    display: table-cell;
    font-size: 14px;
    padding-right: 10px;
  }

  > div {
    vertical-align: middle;
    display: table-cell;
    font-family: ${MediumFont};
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: initial;
    text-transform: capitalize;
    text-align: center;
  }
  @media (max-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div:first-child {
      width: 100%;
      text-align: center;
    }
  }
`;

const DailyFitnessActivityHeader = styled.div`
  color: #FFFFFF;
  height: 55px;
  width: 100%;

/*
  > div:first-child {
    padding: 10px 20px 0 20px;;
    width: 100%;
    display: inline-block;
    float: left;
    .title {
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 8.1936px;
      line-height: 23px;
      letter-spacing: 1px;
      mix-blend-mode: normal;
      opacity: 0.7;
    }
*/

    .goalSetting {
      float: right;
      cursor: pointer;
      font-family: ${RegularFont};
      font-style: normal;
      opacity: 0.7;
      font-size: 9px;

      > span {
        line-height: 22px;
        
        @media (max-width: 767px) {
          margin-right: 20px;
        }
      }

      > i {
        padding-left: 10px;
        right: 25px;
        line-height: 23px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
`;

const Content = styled.div`
  height: calc(100% - 55px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 19px;
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: 350px) {
    padding-top: 15px;
  }
`;

const StepAndDistance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  height: 100%;
  padding: 10px;
  padding-right: ${(props) => (props.left ? "0px" : "10px")}
  padding-left: ${(props) => (props.right ? "0px" : "10px")}
  > img {
    margin-top: 30px;
    width: 30px;
    height: 30px;
  }

  .StepsCount { 
    font-family: ${MediumFont};
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    word-break: break-word;
    margin: 3px 0px;
    width: 100%;
  }

  .TotalSteps {
    font-family: Rubik-Regular;
    font-size: 13px;
    line-height: 10px;
    text-align: center;
    color: rgba(247, 245, 235, 0.50);
  }
  @media (max-width: 1200px) {
  > img {
    width: 30px;
    height: 30px;
  }
  .StepsCount { 
    font-size: 16px;
  }
  .TotalSteps {
    font-size: 10px;
  }
  }
`;

const CaloriesBurned = styled.div`
  width: 150px;
  height: 150px;
  display: inline-block;
  > img {
    margin-top: 35px;
    height: auto;
  }
`;
const MainGraph = styled.div`
  position: relative;
  margin-top: -7px;

  > div:first-child {
    margin-top: 22px;
    width: 100%;
    position: relative;
  }
`;

const DailySummaryHeader = styled.div`
  padding: 20px;
  height: 30%;
  width: 100%;
  float: left;
  padding: 11px 20px;
  text-align: center;

  > span {
    margin-left: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 10.1936px;
    line-height: 13px;
    letter-spacing: 1.2096px;
    text-transform: uppercase;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.7;
  }
`;

const ButtonsContainer = styled.div`
  float: left;
  width: 100%;
`;

const TileButton = styled.button`
  background-color: ${({ active }) => (active ? "#4798D9" : "#031926")};
  border: ${({ active }) =>
    active ? "1px solid #4798D9" : "1px solid #031926"};
  border-radius: 21px;
  color: #ffffff;
  margin-left: 9px;
  margin-top: 15px;
  margin-bottom: 2px;
  padding: 4px 18px;
  font-size: 12px;

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(2) {
    font-weight: bold;
  }

  &:focus,
  &:active,
  &:hover {
    outline: none;
  }

  @media (max-width: 767px) {
    width: auto;
    margin-left: 9px;
  }
`;

const ContentContainer = styled.div`
  padding: 5px 8% 10px;
  float: left;
  width: 100%;
  text-align: left;
  min-height: initial;
`;

const OverviewContainer = styled.div`
  float: left;
  width: 100%;
  position: relative;
  > svg {
    width: 25px;
    margin-right: 5px;
    height: 25px;
  }
`;

const CompleteInformationBox = styled.div`
  display: flex;
  flex-direction: column;
  align-item: flex-start;
  float: right;
`;

const ActivityBar = styled.div`
  height: 7px;
  margin-top: 20px;
  margin-left: 2px;
  width: ${({ wellBeing }) => (wellBeing ? "100%" : "calc(100% - 125px)")};
  background-color: #ededed;
  border-radius: 30px;
  display: inline-block;
`;

const LoaderContainer = styled.div`
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

const InnerActivityBar = styled.div`
  height: 7px;
  width: ${({ width }) => width};
  background-color: ${({ color }) => color};
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;

  ${({ width }) =>
    width === "100%" &&
    css`
      border-radius: 30px;
    `}
`;

const CompleteInformation = styled.div`
  font-family: ${RegularFont};
  font-size: 9.7008px;
  line-height: 16px;
  text-align: center;
  color: #a3b1c6;
  float: right;
  padding: ${(props) => props.padding};
  position: relative;
  text-align: right;
  display: inline-block;

  > div {
    width: 67px;
    float: left;
    display: flex;
    font-family: ${RegularFont};
    font-size: 15px;
    line-height: 21px;
    color: #ffffff;
  }
`;
const AVGContainer = styled.div`
  position: absolute;
  bottom: 17px;
  width: 50px;
  padding: 3px 0;
  background: #f4f4f4;
  border-radius: 100px;
  color: #022c43;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  font-size: 7px;
  font-family: Rubik-Regular;
`;

const ContainerInsideChart = styled.div`
  position: absolute;
  top: 50%;
  width: 50%;
  left: 50%;
  text-align: center;
  font-size: 20px;
  font-family: Rubik-Medium;
  color: #fff;
  letter-spacing: ${(props) => (props.calories > 0 ? "2px" : "0px")};
  transform: translate(-50%, -50%);
  > p {
    margin: 0px;
    font-size: 13px;
    font-family: Rubik-Regular;
    color: #a3b1c6;
    letter-spacing: normal;
  }
  @media (max-width: 1200px) {
    font-size: 18px;
    > p {
      font-size: 10px;
    }
  }
  @media (max-width: 950px) {
    font-size: 20px;
    width: 47%;
    > p {
      font-size: 11px;
    }
  }
  @media (max-width: 400px) {
    font-size: 16px;
    width: 50%;
    > p {
      font-size: 8px;
    }
  }
`;
const CurrentTime = styled.div`
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .week {
    font-size: 20px;
  }
  .title {
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 1px;
    mix-blend-mode: normal;
    opacity: 0.7;
    color: #ffffff;
  }
  > div {
    font-family: ${RegularFont};
    padding: 0 21px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .Date {
    font-family: Rubik-Medium;
    font-size: 16px;
    float: left;
    display: block;
    letter-spacing: 0.8px;
    color: #ffffff;
    text-transform: capitalize;
  }
`;
const LeftArrow = styled.i`
  display: inline-block;
  padding: 5px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  cursor: pointer;
  border-top-color: transparent;
  border-left-color: transparent;

  ${({ disable }) =>
    disable &&
    css`
      border: solid #e1e1e3;
      border-width: 0 3px 3px 0;
      pointer-events: none;
      border-top-color: transparent;
      border-left-color: transparent;
    `}
`;

const DateRightArrow = styled.i`
  display: inline-block;
  padding: 5px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  cursor: pointer;
  border-top-color: transparent;
  border-left-color: transparent;

  ${({ disable }) =>
    disable &&
    css`
      border: solid #e1e1e3;
      border-width: 0 3px 3px 0;
      pointer-events: none;
      border-top-color: transparent;
      border-left-color: transparent;
    `}
`;
const MyDailyActivityContainer = styled.div`
  width: 100%;
  float: left;
  background: white;
  border-radius: 0px 0px 6px 6px;
  padding: 14px 10px;
  >div: first-child {
    color:#005c87 ;
    font-family: ${MediumFont};
    font-size: 13px;
    text-align: center;
    padding: 10px 0;
    text-transform: capitalize;
  }
  >div: last-child {
    width: 100%;
    display: flex;
    align-items: center;
    color: #005c87;
    > div {
      width: calc(100% / 3);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      >div: first-child {
        font-family: ${MediumFont};
        font-size: 16px;
        line-height: 18px;
      }
      >div: last-child {
        font-family: ${RegularFont};
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  float: left;
`;
export {
  Author,
  MainGraph,
  CompleteInformation,
  LoaderContainer,
  InnerActivityBar,
  ActivityBar,
  OverviewContainer,
  ContentContainer,
  TileButton,
  ButtonsContainer,
  DailySummaryHeader,
  CaloriesBurned,
  StepAndDistance,
  Content,
  DailyFitnessActivityHeader,
  RightArrow,
  DailyTip,
  NavigationBox,
  DailySummaryDiv,
  Top,
  BottomContainer,
  Container,
  CaloriesContainer,
  StepsContainer,
  TodaysChallengeDiv,
  CompleteInformationBox,
  DateContainer,
  ChallengeContainer,
  AVGContainer,
  ContainerInsideChart,
  CurrentTime,
  DateRightArrow,
  LeftArrow,
  MyDailyActivityContainer,
  ContentWrapper,
};
